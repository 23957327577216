<template>
    <div class="offer-add">
        <invoice-page order-type="offer" :invoice-creation="!isDuplication"
                      :invoice-duplication="isDuplication" :enableOptionalItems="allowOptionalItems"></invoice-page>
    </div>

</template>

<script>
    import InvoicePage from "../../../components/invoice/InvoicePage";

    export default {
        name: "AddOffer",
        components: {InvoicePage},
        computed: {
            isDuplication() {
                return this.$route.name === 'offers-duplicate' || this.$route.name === 'order-confirmation-create';
            },
            allowOptionalItems() {
                return this.$route.name !== 'order-confirmation-create';
            }
        }
    }
</script>

<style scoped>

</style>